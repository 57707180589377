.calendar {
  z-index: 9999;
}

#createRecurrencesButton{
  margin-top: 1.5rem;
}

#event-list{
  max-width: 80% !important;
  margin: 8% auto 0 !important;
}

#event-list-container {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.datetimeContainer{
}

.descriptionContainer {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.edit-text-area {
  width: 100% !important;
}

.edit-input {
  width: 100% !important;
}

.EventItem {
    border: thin #DDDCDC solid;
    padding: 0 1rem 1rem 2rem  !important;
    margin: 0rem !important;

}

.featuredToggle .checkbox {
  vertical-align: middle;
}

.react-datetime-picker__wrapper{
  border-style: none;
  padding: 0px !important;
}

#recurrenceModal{
  width: 70%;
  height: auto;
  top: 25%;
  left: 15%;
  padding-bottom: 2rem;
}

.row {
  margin-top: .25rem !important;
  margin-bottom: .25rem !important;
}

.stickyToggle {
  margin-top: .5rem;
}

.stickyToggle .checkbox {
  vertical-align: middle;
}

.TagList {
  	margin-top: 5px;
    margin-bottom: 10px;
}

.tag {
    display: inline-block;
    background-color: #F05F6E;
    padding: 5px 10px;
    border-radius: 20px;
    color: white;
  	margin: 0 5px;
}

.tag:after {
  content: 'x';
  display: inline-block;
  background-color: rgba(0,0,0,0.4);
  height: 20px;
  width: 0px;
  text-align: center;
  border-radius: 50px;
  color: #F05F6E;
  transform: rotate(-270deg);
  position: relative;
  left: -20px;
  opacity: 0;
  transition: all linear .3s;
  margin-left: 5px;
  line-height: 1.25em;
}

.tag:hover {
  cursor: pointer;
}

.tag:hover:after {
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  width: 20px;
  height: 20px;
}

.event-img {
  vertical-align: top;
  float: left;
  margin: 0 !important;
}

.event-img img {
  width: 150px;
  height: 150px;
}

.eventItemRightColumn div{
}

.event-url-container div, p {
  display: inline;
}

.image-url-container div, p{
  display: inline;
}

.itemLabel {
  margin-right: 1rem !important;
}

.venueContainer button{
  margin-left: 1rem !important;
}

.Title {
  color: #F05F6E;
  display: inline-block;
}

.Title, .VenueName, .Date {
  margin: 0;
}

.add-tag {
  vertical-align: top !important;
}

.CurationControls {
  float: right;
}

.open-btn {
  display: inline-block;
  vertical-align: top !important;
  color: #F05F6E !important;
  background: transparent !important;
}

.edit-form {
  display: inline;
}

.edit-textarea {
  width: 600px;
  height: 150px;
}

.filter-controls > div.fields {
  display: flex !important;
  justify-content: center;
}


.missing-data-btn {
  color: white !important;
  background-color: #F05F6E !important;
  padding: 5px 8px;
  border-radius: 5px;
  border: none;
}

.event-image-container {
  position: relative;
}

.sensor {
  height: 50px;
}

.text-highlight {
  background: yellow;
  font-weight: bold;
}

.event-checkbox {
  margin: 0rem !important;
}

.statusContainer {
}

.event-recur {
  float: right;
}

.ui.button.chivvyColor{
  background-color: #F0606E;
  color: #fff;
}

.curation-buttons {
  margin: 10px auto 0 !important;
}

.button-col-left,
.button-col-right {
  display: flex !important;
}

.button-col-left {
  justify-content: flex-start;
}

.button-col-right {
  justify-content: flex-end;
}

.buttonsRow {
  margin: 0 auto 0 !important;
}

.header {
  width: 82%;
  background-color: white;
  left: 50%;
}

.sticky {
  position: fixed;
  z-index: 100;
}

.saveButtonColumn {
  width: auto !important;
}
