.accountTypeNote p{
  margin-top: 1rem !important;
}

.registrationContainer {
  width: 80%;
  margin: 5rem auto 1rem auto;
}

.registrationContainer .ribbon.label {
  background-color: #2B3C73 !important;
  color: #fff !important;
}

.registrationContainer .button {
  margin-top: 1rem;
  color: #fff !important;
  background-color: #2B3C73 !important;
}
