.eventbriteImportLabel {
  background-color: #f05537 !important;
  color: #fff !important;
}

.eventbriteImportButton {
  background-color: #f05537 !important;
  color: #fff !important;
}

.myAccountContainer{
  max-width: 80%;
  margin: 60px auto 5rem auto !important;
}

.myAccountContainer .card .extra div{
  margin: .1rem 0 !important;
}

.myAccountContainer .card .extra .label{
  margin-right: .5rem !important;
}

.editProfileModal{
  overflow-y: scroll;
}

.editProfileModal button{
  align-items: center !important;
}

.dimmed.dimmable > .ui.animating.dimmer, .dimmed.dimmable > .ui.visible.dimmer, .ui.active.dimmer {
  display: flex;
  align-items: center;
  justify-content: center;
}
.ui.modal,
.ui.active.modal {
  margin: 0 auto!important;
  top: auto !important;
  left: auto !important;
  transform-origin: center !important;
  transition: all ease .5s;
}
