.App {
	background: url('https://s3.amazonaws.com/exaptive-city-content/chivvy/chivvy-background-map.svg');
	height: 100vh;
	width: 100vw;
}

.App-logo {
-webkit-animation: spin1 2s infinite linear;
-moz-animation: spin1 2s infinite linear;
-o-animation: spin1 2s infinite linear;
-ms-animation: spin1 2s infinite linear;
animation: spin1 2s infinite linear;
display: block;
top: 45%;
left: 47.3%;
}

@-webkit-keyframes spin1 {
0% { -webkit-transform: rotate(0deg);}
100% { -webkit-transform: rotate(360deg);}
}
@-moz-keyframes spin1 {
0% { -moz-transform: rotate(0deg);}
100% { -moz-transform: rotate(360deg);}
}
@-o-keyframes spin1 {
0% { -o-transform: rotate(0deg);}
100% { -o-transform: rotate(360deg);}
}
@-ms-keyframes spin1 {
0% { -ms-transform: rotate(0deg);}
100% { -ms-transform: rotate(360deg);}
}
@-keyframes spin1 {
0% { transform: rotate(0deg);}
100% { transform: rotate(360deg);}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

.App > .container {
	padding-top: 75px;
	height: 100vh;
}

.App .center {
	display: flex;
	justify-content: center;
}

.appNavigation .item:before {
	background: none !important;
}

.appNavigation .item a {
	color: #00AFA0 !important;
}

.appNavigation .item a:focus {
	color: #2B3C73 !important;
}

.appNavigation .item a:active {
	color: #2B3C73 !important;
}

.appNavigation .item a.current-tab {
	color: #2B3C73 !important;
}

.appNavigation .text {
	color: #00AFA0 !important;
	font-weight: bold;
}

.appNavigation i {
	color: #2B3C73 !important;
}

.appNavigation .dropdown{
	margin-right: 2rem;
}

.appNavigation .dropdown .item li {
	color: #2B3C73 !important;
}

.current-tab {
	font-weight: bold;
	color: red;
}

.flexed {
	display: flex !important;
}

.around {
	justify-content: space-around;
}

.between {
	justify-content: space-between;
}

.logo {
	width: 15%;
}

.navBar {
	display: flex;
}
