.addFacebookEvent .fbEvent {
  position: relative;
}

.addFacebookEvent .fbEventCheckbox {
  position: absolute;
  top: 4px;
  left: 0;
}

.addFacebookEvent .fbListIcon {
  margin-right: .5rem !important;
}

.addFacebookEvent .fbEventDescription {
  margin-bottom: 1rem;
}
