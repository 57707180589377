.accordion.content.checkbox {
  display: block;
}

.buttonsRow {
  z-index: 1001;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
}

.curate-event-image {
  vertical-align: top;
  float: left;
  margin: 1rem !important;
}

.curate-event-image img{
  max-width: 200px;
  max-height: 200px;
}

.eventDetailsRow{
  margin-top: .25rem;
}

.featuredToggle .checkbox {
  vertical-align: middle;
}

.filterPopout {
  z-index: 600;
  background-color: white;
  padding: 2%;
  padding-top: 10%;
  position: fixed;
}

#saveButton{
  float: right;
}

.statusFilterColumn{
  align-items: center;
}

.stickyToggle .checkbox {
  vertical-align: middle;
}

.venuesRow h5{
  display: inline !important;
}
