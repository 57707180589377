.forgot-pw-form {
	display: flex;
	flex-direction: column;
	justify-content: center;
	max-width: 500px;
	align-content: center;
	height: 100vh;
	margin: auto;
}

.header {
	margin-top: 15px !important;
}
