.file-drop-target {
	border: 5px dashed #4ec2c1;
	width: 100%;
	height: 75px;
	background: white;
	display: flex;
	align-content: center;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	color: #4ec2c1;
}

.file-drop-target.dragging {
	background: #f0f0f0;
	border-color: #f05f6e;
	color: #f05f6e;
}

#file-selector {
	opacity: 0;
	display: none;
}
