.addDistrictsRow {
  float: right;
  padding-right: 1rem;
}

.container {
  padding-top: 6rem !important;
  height: 100vh;
}

.existingMap {
  height: 100%;
}

.existingMapContainer {
  width: 75% !important;
  padding-bottom: 2rem;
  height: 100%;
}

.menuContainer {
  width: 25% !important;
  align-items: center !important;
  padding-top: 1rem;
  float: right;
}

.menuContainerChild {
  width: 100% !important;
  min-width: 100% !important;
  margin: 0 1rem 1rem 0 !important;
}

.districtsContainer {
    width: 80% !important;
    margin: 60px auto 0 auto !important;
    height: 90% !important;
}

.districtsSaveButton {
  width: 100% !important;
  min-width: 100% !important;
  margin: 0 1rem 1rem -1rem !important;
  align-items: center !important;
}

.districtsSaveButton .label {
  width: 100% !important;
  text-align: center;
  margin-right: 1rem !important;
  margin-left: 0 !important;
}

.menuContainerChild .segment {
  width: 100% !important;
  min-width: 100% !important;
  margin: 0px;
  align-items: center !important;
}

.menuContainerChild .segment .button {
  width: 100% !important;
  text-align: center;
}

.menuContainerChild .label {
  width: 100% !important;
  text-align: center;
}

.newMap {
  height: 100%;
}
