.custom-field {
	width: 100%;
}

.formContainer {
	padding: 0 5%;
}

.custom-field > label {
	display: block;
	margin: 0 0 0.28571429rem 0;
	color: rgba(0, 0, 0, 0.87);
	font-size: 0.92857143em;
	font-weight: 700;
	text-transform: none;
}

.ui.form .field:last-child,
.ui.form .field,
.custom-field {
	margin: 0.5em 0 1em !important;
}

/*
 * Overrides some DateTimePicker styles
 */
.react-datetime-picker__wrapper {
	padding: 0.67857143em 1em;
}
