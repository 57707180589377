.eventPreviewPane {
	width: 100% !important;
}

.item-list {
	margin: auto !important;
	background: white;
	
}

#uploadButtonColumn {
	display: flex;
	justify-content: center;
}

.upload-container {
	margin-left: auto !important;
	margin-right: auto !important;
}

.uploadFileDrop {
	width: 100% !important;
}
