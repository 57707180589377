.add-tag {
  vertical-align: top !important;
}

.event-img {
  display: block !important;
  vertical-align: top;
  margin-right: 20px;
  margin-bottom: 20px;
  float: right;
}

.event-img img {
  width: 150px;
  height: 150px;
}

.event-checkbox {
  display: inline;
  float: right;
  margin-top: 1rem;
  margin-right: 1.5rem;
}

.event-checkbox.checkbox {
  vertical-align: middle !important;
}

.eventControlsImageContainer {
  padding-right: 0px !important;
}

.eventDescriptionRow {
  margin-left: 0px !important;
}

.eventImageRow {
  margin-right: 0px !important;
}

.EventItem.producerLive{
    border: thin #DDDCDC solid;
    padding: 1rem 1rem 1rem 2rem !important;
}

.liveControlButtons {
  float: right;
}

.TagList {
  	margin-top: 5px;
    margin-bottom: 10px;
}

.tag {
    display: inline-block;
    background-color: #F05F6E;
    padding: 5px 10px;
    border-radius: 20px;
    color: white;
  	margin: 0 5px;
}

.Title {
  color: #F05F6E;
  display: inline-block;
}

.Title, .VenueName, .Date {
  margin: 0;
}

.open-btn {
  display: inline-block;
  vertical-align: top !important;
  color: #F05F6E !important;
  background: transparent !important;
}

.edit-form {
  display: inline;
}

.edit-textarea {
  width: 600px;
  height: 150px;
}

.filter-controls > div.fields {
  display: flex !important;
  justify-content: center;
}

.missing-data-btn {
  color: white !important;
  background-color: #F05F6E !important;
  padding: 5px 8px;
  border-radius: 5px;
  border: none;
}

.event-image-container {
  position: relative;
}

.sensor {
  height: 50px;
}

.SocialMediaShareButton{
  display: inline !important;
}

.text-highlight {
  background: yellow;
  font-weight: bold;
}

.ui.grid>*{
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.exportButton > * {
  color: #f05f6e !important;
}

.button-col-left,
.button-col-right {
  display: flex !important;
}

.button-col-left {
  justify-content: flex-start;
}

.button-col-right {
  justify-content: flex-end;
}

.header {
  width: 82%;
  background-color: white;
  left: 50%;
}

.sticky {
  position: fixed;
  z-index: 100;
}

.district {
  display: inline-block;
  font-weight: bold;
}

.districts-title {
  display: inline;
}

.htmlEmbedModal{
  height: auto;
  top: 25%;
  left: 25%;
}
