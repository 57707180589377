/*
[data-node="comp1"]{
  background: #FFF;
}
*/

.container {
  width: 80%;
}

.itemContainer {
  width: 100%;
}

.itemContainer .image {
  display: inline-block;
  vertical-align: top;
  margin-right: .5rem !important;
  margin-bottom: .5rem !important;
  float: left;
}

.saveButtonAndLabel{
  float: right;
}

.stickyLabel{
  float: right;
}

.featuredLabel{
  float: right;
}

.xc-toggle-list {
  padding: 10px;
  max-width: 80%;
  margin: 0 auto;
}

#save-btn {
  text-align: center;
}

.toggler {
  float: right;
  width: 3.5rem !important;
}

.toggler input {
  width: 3.5rem !important;
  margin-left: 0 !important;
}

.toggler input:focus:checked~.box:before,
.toggler input:focus:checked~label:before {
    background-color: green !important
}

.toggler .box:before, .toggle label:before {
    background-color: grey;
}

.toggler input:checked ~ .box:before,
.toggler input:checked ~ label:before {
  background-color: green !important;
}
.toggleContainer {
  float: right;
}

.xc-toggle-list .sixteen.wide.column {
  display: flex;
  justify-content: center;
  padding-bottom: 0 !important;
  height: 32px;
}

.xc-toggle-list .items .item {
  padding: 1em !important;
  background-color: white;
  border-right: 1px solid rgba(34,36,38,.15);
  border-left: 1px solid rgba(34,36,38,.15);
}

.xc-toggle-list .label {
    background-color: #F05F6E;
    color: white;
    border-radius: 50px;
}

.meta {
  color: black !important;
}

.xc-toggle-list .toggle-label {
  margin-right: 10px;
}

.xc-toggle-list .grid {
  margin-bottom: 0 !important;
}

.xc-toggle-list .item .image img {
  max-height: 175px;
}

/** need media query so the padding is gone on mobile screens */
.xc-toggle-list .description {
  padding-right: 200px;
}
