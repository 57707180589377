.login-form {
	display: flex;
	flex-direction: column;
	justify-content: center;
	max-width: 500px;
	align-content: center;
	height: 100vh;
	margin: auto;
}

.logo {
	margin-bottom: 50px;
}

.links {
	padding: 25px;
	text-align: center;
}

.links a {
	color: #00AFA0 !important;
}

.loginButton {
	background-color: #2B3C73 !important;
	color: #fff !important;
}

.registerButton {
	background-color: #395099 !important;
	color: #fff !important;
}
